<template>
  <div>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <h2 class="pl-1">
      <strong>Reassign Activity</strong>
    </h2>
    <hr>
    <div class="pl-1">
      <b-row>
        <b-col>
          <div>Action Number: {{ actionObj.ActionFileNumber }}</div>
        </b-col>
      </b-row>
      <br/>
      <b-row>
        <b-col>
          <div>Activity ID: {{ ActivityID }}</div>
        </b-col>
      </b-row>
      <br/>
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Assignee"
            label-for="assignee"
          >
            <v-select
              id="assignee"
              v-model="Assignee"
              label="title"
              :options="assigneeList"
              name="assignee"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-5">
        <b-col>
          <b-button
            class="mr-2"
            type="reset"
            variant="danger"
            @click="resetForm()"
          >
            Cancel
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            @click="saveForm()"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import APIService from "@core/utils/APIService";
import {BBreadcrumb, BButton, BCol, BFormGroup, BRow} from "bootstrap-vue";
import vSelect from "vue-select";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BBreadcrumb,
    BButton,
  },
  data() {
    return {
      ActivityID: "",
      ActionID: "",
      Assignee: null,
      actionObj: {},
      activityObj: {},
      assigneeList: [],
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Cases',
          href: '/cases'
        },
        {
          text: this.actionObj.CaseFileNumber,
          href: '/cases/' + this.actionObj.CaseID + '/detail'
        },
        {
          text: this.actionObj.ActionFileNumber,
        },
      ]
    },
  },
  created() {
    this.ActivityID = this.$route.params.activityID;
    this.ActionID = this.$route.params.actionID;
    if (this.ActionID) {
      this.loadAction();
    }
  },
  methods: {
    loadAction() {
      apiService
          .get("action/" + this.ActionID + "/detail")
          .then(res => {
            this.actionObj = res.data;
            this.assigneeList = res.data.Assignments.map(item => {
              return {title: item.Fullname, value: item.UserAccountID}
            })
            if (this.ActivityID) {
              this.loadActivity();
            }
          })
    },

    loadActivity() {
      apiService
          .get("activity/" + this.ActivityID + "/detail")
          .then(res => {
            this.activityObj = res.data;
            this.Assignee = this.assigneeList.filter(i => i.value === this.activityObj.InvestigatorID)
          })
    },

    saveForm() {
      const endpoint = "activity/reassign";
      const postData = {
        activityId: this.ActivityID,
        investigatorId: this.Assignee.value
      }
      apiService
          .post(endpoint, postData)
          .then(res => {
            this.$router.push('/cases/edit-action/' + this.ActionID);
          })
    },

    resetForm() {
      this.$router.push('/cases/edit-action/' + this.ActionID);
    },
  }
}
</script>

<style scoped>

</style>